import { useAuth } from "../providers/AuthProvider";
import {
    Box,
    Button,
    Typography,
    Tabs,
    Tab,
    Dialog,
    DialogContent,
    DialogActions,
} from "@mui/material";
import { useState } from "react";
import ResultTabPanel from "./ResultTabPanel";
import { formatDateDDMMYYYY } from "../constObjects";

const ResultPopup = ({
    open,
    setOpen,
    week,
    match,
    createdAt,
    homeTeam,
    homeTeamCpt,
    homeTeamRoster,
    homeTeamSs,
    homeTeamSp,
    homeTeamTotalSs,
    homeTeamTotalSp,
    awayTeam,
    awayTeamCpt,
    awayTeamRoster,
    awayTeamSs,
    awayTeamSp,
    awayTeamTotalSs,
    awayTeamTotalSp,
}) => {
    const [resultTabView, setResultTabView] = useState(0);

    const tabProps = (index) => ({
        id: `result-tab-${index}`,
        "aria-controls": `result-tabpanel-${index}`,
    });

    // const resultDate = new Date(createdAt.seconds * 1000);

    let winner, loser;
    if (homeTeamTotalSs > awayTeamTotalSs) {
        winner = homeTeam;
        loser = awayTeam;
    } else if (awayTeamTotalSs > homeTeamTotalSs) {
        winner = awayTeam;
        loser = homeTeam;
    }
    const { currentUser } = useAuth();

    const getWinningTeamColor = (team) => {
        let color;
        if (team === winner) color = "green";
        else if (team === loser) color = "red";
        return color;
    };

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={() => setOpen(false)}
            sx={{ height: "90vh" }}
        >
            <DialogContent>
                <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="baseline" gap={1}>
                        <Typography
                            id="match-result-modal-title"
                            variant="h6"
                            component="h2"
                        >
                            Hafta {week}
                        </Typography>
                        <Typography
                            id="match-result-modal-date"
                            variant="body2"
                        >
                            ({formatDateDDMMYYYY(createdAt)})
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="baseline" gap={2}>
                        <Typography
                            variant="body1"
                            sx={{ color: getWinningTeamColor(homeTeam) }}
                        >
                            {homeTeam}
                        </Typography>
                        <Typography variant="body2">vs</Typography>
                        <Typography
                            variant="body1"
                            sx={{ color: getWinningTeamColor(awayTeam) }}
                        >
                            {awayTeam}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            m: "auto",
                            width: "100%",
                        }}
                    >
                        <Box
                            sx={{
                                borderBottom: 1,
                                borderColor: "divider",
                                width: "100%",
                            }}
                        >
                            <Tabs
                                value={resultTabView}
                                onChange={(event, newValue) => {
                                    setResultTabView(newValue);
                                }}
                            >
                                <Tab
                                    key={`result-tab-0`}
                                    label={homeTeam}
                                    {...tabProps(0)}
                                />
                                <Tab
                                    key={`result-tab-1`}
                                    label={awayTeam}
                                    {...tabProps(1)}
                                />
                            </Tabs>
                        </Box>
                        <ResultTabPanel
                            key="result-tab-panel-0"
                            roster={homeTeamRoster}
                            cpt={homeTeamCpt}
                            ss={homeTeamSs}
                            sp={homeTeamSp}
                            totalSs={homeTeamTotalSs}
                            totalSp={homeTeamTotalSp}
                            value={resultTabView}
                            index={0}
                        />
                        <ResultTabPanel
                            key="result-tab-panel-1"
                            roster={awayTeamRoster}
                            cpt={awayTeamCpt}
                            ss={awayTeamSs}
                            sp={awayTeamSp}
                            totalSs={awayTeamTotalSs}
                            totalSp={awayTeamTotalSp}
                            value={resultTabView}
                            index={1}
                        />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                {currentUser?.admin && (
                    <Button
                        href={`/sonuc-duzenle/w${week}m${match}`}
                        variant="contained"
                    >
                        Düzenle
                    </Button>
                )}
                <Button onClick={() => setOpen(false)} variant="outlined">
                    Kapat
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ResultPopup;
