import { db } from "../firebase/setup";
import {
    doc,
    getDocs,
    addDoc,
    serverTimestamp,
    where,
    query,
    collection,
    deleteDoc,
    getDoc,
    updateDoc,
} from "firebase/firestore";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";
import { collectionName, errorCodes, severity } from "../constObjects";
import {
    Card,
    CardContent,
    Typography,
    TextField,
    Button,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Container,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setFeedback, setLoading } from "../store/feedbackSlice";

const Admin = () => {
    const { currentUser } = useAuth();

    const [teamList, setTeamList] = useState([]);
    const [removeTeamList, setRemoveTeamList] = useState([]);
    const [removeTeam, setRemoveTeam] = useState("");
    const [addTeam, setAddTeam] = useState("");
    const [removePlayer, setRemovePlayer] = useState("");
    const [addPlayer, setAddPlayer] = useState("");
    const [addPlayerPosition, setAddPlayerPosition] = useState("");
    const [results, setResults] = useState([]);
    const [resultToDisplay, setResultToDisplay] = useState("");
    const [matchWeek, setMatchWeek] = useState("");

    const redirect = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        // get team list
        const getTeamList = async () => {
            const usersRef = collection(db, collectionName.users);
            const usersSnapshot = await getDocs(usersRef);
            if (!usersSnapshot.empty) {
                usersSnapshot.docs.forEach((doc) => {
                    setTeamList((prev) => [...prev, doc.data().team]);
                });
            }
        };
        const getResults = async () => {
            dispatch(setLoading(true));
            try {
                const resultsRef = collection(db, collectionName.results);
                const resultsSnapshots = await getDocs(resultsRef);
                resultsSnapshots.docs.forEach((doc) => {
                    setResults((prev) => [...prev, doc.data()]);
                });
            } catch (e) {
                const errorObj = errorCodes.filter(
                    (error) => error.code === e.code
                );
                let errorMsg = Object.keys(errorObj).length
                    ? errorObj[0].message || ""
                    : "Tanımlanmamış hata.";
                dispatch(
                    setFeedback({ severity: severity.error, message: errorMsg })
                );
            }
            dispatch(setLoading(false));
        };
        const getMatchWeek = async () => {
            const controlSnap = await getDoc(
                doc(db, collectionName.control, "control_doc")
            );
            if (controlSnap.exists()) {
                setMatchWeek(controlSnap.data().weekNumber);
            }
        };

        //eslint-disable-next-line
        getTeamList();
        getResults();
        getMatchWeek();
    }, [dispatch]);

    useEffect(() => {
        setRemoveTeamList([]);
        const getRemoveTeam = async () => {
            let tempRemoveTeamList = [];
            const removeTeamListRef = collection(db, collectionName.players);
            const removeTeamListSnapshot = await getDocs(
                query(removeTeamListRef, where("team", "==", removeTeam))
            );
            if (!removeTeamListSnapshot.empty) {
                removeTeamListSnapshot.docs.forEach((doc) => {
                    tempRemoveTeamList.push({
                        name: doc.data().player,
                        id: doc.id,
                    });
                });
            }
            tempRemoveTeamList.sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
            setRemoveTeamList(tempRemoveTeamList);
        };
        getRemoveTeam();
    }, [removeTeam]);

    const handleRemove = async (e) => {
        e.preventDefault();
        dispatch(setLoading(true));
        try {
            // check if own team
            // eslint-disable-next-line
            if (!currentUser.admin) throw { code: "admin/not-admin" };

            // delete player
            const playersRef = doc(db, collectionName.players, removePlayer);
            await deleteDoc(playersRef);
            dispatch(
                setFeedback({
                    severity: severity.success,
                    message: "Oyuncu silindi.",
                })
            );
            redirect(0);
        } catch (e) {
            const errorObj = errorCodes.filter(
                (error) => error.code === e.code
            );
            let errorMsg = Object.keys(errorObj).length
                ? errorObj[0].message || ""
                : "Tanımlanmamış hata.";
            dispatch(
                setFeedback({ severity: severity.error, message: errorMsg })
            );
        }
        dispatch(setLoading(false));
    };
    const handleAdd = async (e) => {
        e.preventDefault();
        dispatch(setLoading(true));
        try {
            // eslint-disable-next-line
            if (!currentUser.admin) throw { code: "admin/not-admin" };
            // add player to his team
            await addDoc(collection(db, collectionName.players), {
                player: addPlayer.toUpperCase(),
                position: addPlayerPosition,
                team: addTeam,
                createdAt: serverTimestamp(),
            });
            dispatch(
                setFeedback({
                    severity: severity.success,
                    message: "Oyuncu eklendi.",
                })
            );
            redirect(0);
        } catch (e) {
            const errorObj = errorCodes.filter(
                (error) => error.code === e.code
            );
            let errorMsg = Object.keys(errorObj).length
                ? errorObj[0].message || ""
                : "Tanımlanmamış hata.";
            dispatch(
                setFeedback({ severity: severity.error, message: errorMsg })
            );
        }
        dispatch(setLoading(false));
    };
    const goToEditResult = (e) => {
        e.preventDefault();
        redirect(`/sonuc-duzenle/${resultToDisplay}`);
    };

    const handleMatchWeek = async (value) => {
        dispatch(setLoading(true));
        try {
            setMatchWeek(value);
            const controlRef = doc(db, collectionName.control, "control_doc");
            await updateDoc(controlRef, { weekNumber: value });
            dispatch(
                setFeedback({
                    severity: severity.success,
                    message: `Yeni maç haftası --> ${value}`,
                })
            );
        } catch (e) {
            const errorObj = errorCodes.filter(
                (error) => error.code === e.code
            );
            let errorMsg = Object.keys(errorObj).length
                ? errorObj[0].message || ""
                : "Tanımlanmamış hata.";
            dispatch(
                setFeedback({ severity: severity.error, message: errorMsg })
            );
        }
        dispatch(setLoading(false));
    };

    return (
        <Container component="main" maxWidth="sm">
            <Typography variant="h4" textAlign="center">
                Admin Paneli
            </Typography>

            <Card
                variant="outlined"
                style={{ margin: "1rem 0", width: "100%" }}
            >
                <CardContent>
                    <form
                        onSubmit={handleRemove}
                        style={{
                            display: "flex",
                            gap: "1rem",
                            flexWrap: "wrap",
                        }}
                    >
                        <FormControl fullWidth>
                            <InputLabel>Takım</InputLabel>
                            <Select
                                label="Takım"
                                name="removeTeam"
                                id="removeTeam"
                                value={removeTeam || ""}
                                onChange={(e) => setRemoveTeam(e.target.value)}
                                required
                            >
                                {teamList.map((team) => (
                                    <MenuItem
                                        key={`option-${team}`}
                                        value={team}
                                    >
                                        {team}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel>Silinecek Oyuncu</InputLabel>
                            <Select
                                label="Silinecek Oyuncu"
                                name="removePlayer"
                                id="removePlayer"
                                value={removePlayer || ""}
                                onChange={(e) =>
                                    setRemovePlayer(e.target.value)
                                }
                                required
                            >
                                {removeTeamList.map((player) => (
                                    <MenuItem key={player.id} value={player.id}>
                                        {player.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            fullWidth
                        >
                            Oyuncu Sil
                        </Button>
                    </form>
                </CardContent>
            </Card>

            <Card
                variant="outlined"
                style={{ margin: "1rem 0", width: "100%" }}
            >
                <CardContent>
                    <form
                        onSubmit={handleAdd}
                        style={{
                            display: "flex",
                            gap: "1rem",
                            flexWrap: "wrap",
                        }}
                    >
                        <TextField
                            label="Eklenecek Oyuncu"
                            name="addPlayer"
                            id="addPlayer"
                            fullWidth
                            onChange={(e) => setAddPlayer(e.target.value)}
                            required
                        />
                        <FormControl fullWidth>
                            <InputLabel>Mevki</InputLabel>
                            <Select
                                label="Mevki"
                                name="addPlayerPosition"
                                id="addPlayerPosition"
                                value={addPlayerPosition || ""}
                                onChange={(e) =>
                                    setAddPlayerPosition(e.target.value)
                                }
                                required
                            >
                                <MenuItem value="gk">Kaleci</MenuItem>
                                <MenuItem value="df">Savunma</MenuItem>
                                <MenuItem value="mf">Orta Saha</MenuItem>
                                <MenuItem value="fw">Hücum</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel>Takım</InputLabel>
                            <Select
                                label="Takım"
                                name="addTeam"
                                id="addTeam"
                                value={addTeam || ""}
                                onChange={(e) => setAddTeam(e.target.value)}
                                required
                            >
                                {teamList.map((team) => (
                                    <MenuItem
                                        key={`option-${team}`}
                                        value={team}
                                    >
                                        {team}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            fullWidth
                        >
                            Oyuncu Ekle
                        </Button>
                    </form>
                </CardContent>
            </Card>

            <Card
                variant="outlined"
                style={{ margin: "1rem 0", width: "100%" }}
            >
                <CardContent>
                    <form
                        onSubmit={goToEditResult}
                        style={{
                            display: "flex",
                            gap: "1rem",
                            flexWrap: "wrap",
                        }}
                    >
                        <FormControl fullWidth>
                            <InputLabel>Maç Sonucu</InputLabel>
                            <Select
                                label="Maç Sonucu"
                                name="result"
                                id="result"
                                value={resultToDisplay || ""}
                                onChange={(e) =>
                                    setResultToDisplay(e.target.value)
                                }
                                required
                            >
                                {results.map((result) => (
                                    <MenuItem
                                        key={`option-w${result.week}m${result.match}`}
                                        value={`w${result.week}m${result.match}`}
                                    >
                                        {`${result.week}. Hafta - ${result.homeTeam} vs ${result.awayTeam}`}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            fullWidth
                        >
                            Düzenle
                        </Button>
                    </form>
                </CardContent>
            </Card>
            <Card
                variant="outlined"
                style={{ margin: "1rem 0", width: "100%" }}
            >
                <CardContent>
                    <FormControl fullWidth>
                        <InputLabel>Maç Haftası</InputLabel>
                        <Select
                            label="Maç Haftası"
                            name="matchWeek"
                            id="matchWeek"
                            value={matchWeek || ""}
                            onChange={(e) => handleMatchWeek(e.target.value)}
                            required
                        >
                            {[...Array(20).keys()].map((week) => (
                                <MenuItem key={week + 1} value={week + 1}>
                                    Hafta {week + 1}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </CardContent>
            </Card>
        </Container>
    );
};

export default Admin;
