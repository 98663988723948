import { collection, getDocs, getDoc, doc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase/setup";
// import { useAuth } from "../providers/AuthProvider";
import { styled } from "@mui/material/styles";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Box,
    Container,
    Link,
} from "@mui/material";
import DisplayResultSelector from "../components/DisplayResultSelector";
import ResultPopup from "../components/ResultPopup";
import Fixture from "../components/Fixture";
import { collectionName } from "../constObjects";

const leagueTableHeaders = [
    "Takim",
    "Form Durumu",
    "G",
    "B",
    "M",
    "SS",
    "P",
    "SP",
    "LP",
];

// const matchString = (result) => {
//     return `${result["week"]}. Hafta - ${result["homeTeam"]} vs ${result["awayTeam"]}`;
// };

const League = () => {
    // const { currentUser } = useAuth();
    const [teams, setTeams] = useState([]);
    const [results, setResults] = useState([]);
    const [fixtures, setFixtures] = useState([]);
    const [resultToDisplay, setResultToDisplay] = useState("");
    const [popupOpen, setPopupOpen] = useState(false);
    const [weekNumber, setWeekNumber] = useState(0);

    useEffect(() => {
        const getStandings = async () => {
            try {
                let leagueData = [];
                // get from firestore
                const standingsRef = collection(db, collectionName.standings);
                const standingsSnapshots = await getDocs(standingsRef);
                standingsSnapshots.docs.forEach((doc) => {
                    const temp = { ...doc.data(), name: doc.id };
                    leagueData.push(temp);
                });
                // create league table
                let standings = [];
                leagueData.forEach((data) => {
                    let totalPt = data.points.reduce(
                        (prev, cur) => prev + cur,
                        0
                    );
                    let totalSp = data.sps.reduce((prev, cur) => prev + cur, 0);
                    let totalLp = totalPt + 0.1 * totalSp;
                    standings.push({
                        name: data.name,
                        form: data.forms.slice(-5),
                        w: data.wins.reduce((prev, cur) => prev + cur, 0),
                        d: data.draws.reduce((prev, cur) => prev + cur, 0),
                        l: data.losses.reduce((prev, cur) => prev + cur, 0),
                        ss: data.sss.reduce((prev, cur) => prev + cur, 0),
                        sp: totalSp,
                        pts: totalPt,
                        lp: totalLp,
                    });
                });

                standings.sort((a, b) => {
                    if (a.lp === b.lp) {
                        return b.ss - a.ss;
                    }
                    return b.lp - a.lp;
                });
                setTeams(standings);
            } catch (error) {
                console.log("error", error);
            }
        };

        const getResults = async () => {
            try {
                // setLoading(true);
                // setError("");
                const resultsRef = collection(db, collectionName.results);
                const resultsSnapshots = await getDocs(resultsRef);
                let tempResults = [];

                resultsSnapshots.docs.forEach((doc) => {
                    tempResults.push({
                        id: doc.id,
                        ...doc.data(),
                    });
                    // setResults((prev) => [...prev, doc.data()]);
                });

                tempResults.sort((a, b) => {
                    const aMatches = a.id.match(/w(\d+)m(\d+)/);
                    const bMatches = b.id.match(/w(\d+)m(\d+)/);

                    if (!aMatches || !bMatches) return 0;

                    const aWeek = parseInt(aMatches[1]);
                    const bWeek = parseInt(bMatches[1]);
                    const aMatch = parseInt(aMatches[2]);
                    const bMatch = parseInt(bMatches[2]);

                    if (aWeek !== bWeek) {
                        return bWeek - aWeek;
                    }

                    return bMatch - aMatch;
                });

                setResults(tempResults);
            } catch (error) {
                // setError("Sonuçlara ulaşılamadı.");
            }
            // setLoading(false);
        };
        const getFixtures = async () => {
            try {
                // setLoading(true);
                // setError("");
                const fixturesRef = collection(db, collectionName.fixtures);
                const fixturesSnapshots = await getDocs(fixturesRef);
                let tempFixtures = [];
                fixturesSnapshots.docs.forEach((doc) => {
                    // setFixtures(prev => [...prev, doc.data()]);
                    tempFixtures.push(doc.data());
                });
                setFixtures(
                    transformFixtureData(tempFixtures).sort(
                        (a, b) => a.week - b.week
                    )
                );
            } catch (error) {
                // setError("Sonuçlara ulaşılamadı.");
            }
            // setLoading(false);
        };
        const getControlData = async () => {
            const controlSnap = await getDoc(
                doc(db, collectionName.control, "control_doc")
            );
            if (controlSnap.exists()) {
                setWeekNumber(controlSnap.data().weekNumber);
            }
        };
        const getUserIds = async () => {
            const usersRef = collection(db, collectionName.users);
            const usersSnapshot = await getDocs(usersRef);
            if (!usersSnapshot.empty) {
                usersSnapshot.docs.forEach((doc) => {
                    const teamName = doc.data().team;
                    setTeams((prev) => {
                        const newTeamsArray = prev.map((p) => {
                            if (p.name === teamName) {
                                return { ...p, id: doc.id };
                            }
                            return p;
                        });
                        return newTeamsArray;
                    });
                });
            }
        };
        getStandings();
        getResults();
        getFixtures();
        getControlData();
        getUserIds();
        // eslint-disable-next-line
    }, []);

    const transformFixtureData = (data) => {
        return data.reduce((acc, current) => {
            const weekIndex = acc.findIndex(
                (item) => item.week === current.week
            );
            const match = {
                homeTeam: current.homeTeam,
                awayTeam: current.awayTeam,
            };

            if (weekIndex === -1) {
                acc.push({ week: current.week, matches: [match] });
            } else {
                acc[weekIndex].matches.push(match);
            }

            return acc;
        }, []);
    };

    return (
        <Container
            component="main"
            maxWidth="md"
            sx={{
                mb: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <TableContainer component={Paper}>
                <Typography variant="h6" align="center" gutterBottom>
                    Puan Durumu
                </Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            {leagueTableHeaders.map((header) => (
                                <TableCell key={`th-${header}`}>
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {teams.map((team) => (
                            <TableRow key={`tr-${team.name}`}>
                                <TableCell>
                                    <Link href={`/profil/${team?.id}`}>
                                        {team.name}
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    {renderLatestResults(team.form)}
                                </TableCell>
                                <TableCell>{team.w}</TableCell>
                                <TableCell>{team.d}</TableCell>
                                <TableCell>{team.l}</TableCell>
                                <TableCell>{team.ss.toFixed(1)}</TableCell>
                                <TableCell>{team.pts}</TableCell>
                                <TableCell>{team.sp}</TableCell>
                                <TableCell>{team.lp.toFixed(1)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <DisplayResultSelector
                matchResults={results}
                setPopupOpen={setPopupOpen}
                resultToDisplay={resultToDisplay}
                setResultToDisplay={setResultToDisplay}
            />
            {popupOpen &&
                // eslint-disable-next-line
                results.map((result, index) => {
                    let displayMatch = resultToDisplay.split("m")[1];
                    let displayWeek = resultToDisplay
                        .split("m")[0]
                        .split("w")[1];
                    if (
                        displayMatch === result.match &&
                        displayWeek === result.week
                    ) {
                        return (
                            <ResultPopup
                                open={popupOpen}
                                setOpen={setPopupOpen}
                                key={`w${result.week}m${result.match}-${index}`}
                                week={result.week}
                                match={result.match}
                                createdAt={result.createdAt}
                                homeTeam={result.homeTeam}
                                homeTeamCpt={result.homeTeamCpt}
                                homeTeamRoster={result.homeTeamRoster}
                                homeTeamSs={result.homeTeamSs}
                                homeTeamSp={result.homeTeamSp}
                                homeTeamTotalSs={result.homeTeamTotalSs}
                                homeTeamTotalSp={result.homeTeamTotalSp}
                                awayTeam={result.awayTeam}
                                awayTeamCpt={result.awayTeamCpt}
                                awayTeamRoster={result.awayTeamRoster}
                                awayTeamSs={result.awayTeamSs}
                                awayTeamSp={result.awayTeamSp}
                                awayTeamTotalSs={result.awayTeamTotalSs}
                                awayTeamTotalSp={result.awayTeamTotalSp}
                            />
                        );
                    }
                })}
            <Fixture fixtures={fixtures} weekNumber={weekNumber} />
        </Container>
    );
};

const renderLatestResults = (results) => {
    return results.map((result, index) => (
        <ResultIcon key={index} result={result}>
            {result === "w" && (
                <CheckCircleOutlineIcon sx={{ fontSize: { xs: 8, sm: 16 } }} />
            )}
            {result === "d" && (
                <RemoveCircleOutlineIcon sx={{ fontSize: { xs: 8, sm: 16 } }} />
            )}
            {result === "l" && (
                <CancelOutlinedIcon sx={{ fontSize: { xs: 8, sm: 16 } }} />
            )}
        </ResultIcon>
    ));
};

const ResultIcon = styled(Box)(({ result }) => ({
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    width: { xs: 8, sm: 16 },
    height: { xs: 8, sm: 16 },
    borderRadius: "50%",
    marginRight: 2,
    color: "white",
    backgroundColor: getResultColor(result),
}));

const getResultColor = (result) => {
    switch (result) {
        case "w":
            return "green";
        case "d":
            return "orange";
        case "l":
            return "red";
        default:
            return "black";
    }
};

export default League;
